import React, { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../Hooks/ScreenDimensionsHook";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import useWindowHeight from "../../Hooks/ScreenHeight";

const steps = [
  {
    title: "Conceptualization & Roadmapping",
    content:
      "In-depth discussion to understand your project's objectives, requirements, and vision. Our team conducts feasibility studies and technology assessments to determine the best-fit solutions for your unique needs",
  },
  {
    title: "Prototyping",
    content:
      "Our skilled architects transform your ideas into tangible prototypes. Based on our client's input, we refine and validate the proposed solution, ensuring alignment with their vision and objectives and setting the stage for successful execution.",
  },
  {
    title: "Development",
    content:
      "With the blueprint in hand, our expert developers bring your solution to life. Regular progress updates and demos keep you informed and engaged throughout the process.",
  },
  {
    title: "Deployment and Optimization",
    content:
      "Our team will deploy with precision. We carefully migrate your solution to its live environment, providing ongoing support and maintenance to ensure continued performance, keeping your business operations running smoothly.",
  },
];

export const OurDevelopmentPipeline: React.FC = () => {
  const width = useWindowDimensions();
  const height = useWindowHeight();
  const isMobile = width < 768;
  const sectionRef = useRef<HTMLDivElement>(null);
  const [isLandscape, setIsLandscape] = useState<boolean>(false);
  console.log("====================================");
  console.log(height, "px", isLandscape);
  console.log("====================================");
  useEffect(() => {
    const handleResize = () => {
      // Перевірка на ландшафтний режим
      setIsLandscape(width < 855 && height < 400);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width, height]);

  const preventSingleWordWrap = (text: string) => {
    const words = text.split(" ");
    if (words.length > 2) {
      return (
        words.slice(0, -2).join(" ") + "\u00A0" + words.slice(-2).join("\u00A0")
      );
    }
    return text;
  };

  useGSAP(() => {
    gsap.fromTo(
      ".animate-box",
      {
        opacity: 0,
        y: -200,
      },
      {
        opacity: 1,
        y: 0,
        duration: 3,
        stagger: 2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 90%",
          end: "bottom 80%",
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      ".animate-text",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        stagger: 2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 100%",
          end: "bottom 90%",
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      ".heading",
      {
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 100%",
          scrub: true,
        },
      },
      {
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 80%",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <div ref={sectionRef}>
      <div className="h-auto pb-12 xxl:pb-20 sm:pb-6 flex justify-start items-start flex-row">
        <div className="self-stretch text-white text-5xl sm:text-[32px] font-bold leading-10 heading">
          Our Development Pipeline
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-5">
        {isMobile ? (
          <>
            <div
              ref={sectionRef}
              className="flex flex-col justify-center items-center animate-box"
            >
              <div className="w-[95%] ">
                <div
                  className={`text-center text-white text-2xl leading-loose sm:text-sm animate-text pb-3 ${
                    isLandscape && "text-xl"
                  }`}
                >
                  {steps[0].title}
                </div>
                <div className="text-center text-zinc-400 text-sm font-normal leading-tight sm:text-xs animate-text">
                  {preventSingleWordWrap(steps[0].content)}
                </div>
              </div>
              <div
                className={`w-[100%] h-9 rounded-[100%] border-b-8 border-x-8 border-b-sky-700 border-x-sky-800 flex justify-center pb-10s`}
              />
            </div>

            <div className="flex flex-col justify-center items-center animate-box">
              <div className=" w-[90%]">
                <div className="text-center text-white text-2xl leading-loose sm:text-sm animate-text pb-3">
                  {steps[1].title}
                </div>
                <div className="text-center text-zinc-400 text-sm font-normal leading-tight sm:text-xs animate-text">
                  {preventSingleWordWrap(steps[1].content)}
                </div>
              </div>
              <div
                className={`w-[95%] h-9 rounded-[100%] border-b-8 border-x-8 border-b-sky-700 border-x-sky-800 flex justify-center`}
              />
            </div>

            <div className="flex flex-col justify-center items-center animate-box">
              <div className="w-[85%]">
                <div className="text-center text-white text-2xl leading-loose sm:text-sm animate-text pb-3">
                  {steps[2].title}
                </div>
                <div className="text-center text-zinc-400 text-sm font-normal leading-tight sm:text-xs animate-text">
                  {preventSingleWordWrap(steps[2].content)}
                </div>
              </div>
              <div
                className={`w-[90%] h-9 rounded-[100%] border-b-8 border-x-8 border-b-sky-700 border-x-sky-800 flex justify-center`}
              />
            </div>

            <div className="flex flex-col justify-center items-center animate-box">
              <div className="w-[80%] justify-center">
                <div className="text-center text-white text-2xl leading-loose sm:text-sm animate-text pb-3">
                  {steps[3].title}
                </div>
                <div className="text-center text-zinc-400 text-sm font-normal leading-tight sm:text-xs animate-text">
                  {preventSingleWordWrap(steps[3].content)}
                </div>
              </div>
              <div
                className={`w-[85%] h-9 rounded-[100%] border-b-8 border-x-8 border-b-sky-700 border-x-sky-800 flex justify-center items-center`}
              />
            </div>
          </>
        ) : (
          <>
            <div
              className={`w-[100%] h-auto rounded-[100%] border-b-8 border-x-8 border-b-sky-700 border-x-sky-800 flex justify-center animate-box`}
            >
              <div className="flex flex-col pb-12 w-[50%] xxl:pt-8 pt-8">
                <div className=" text-center text-white text-2xl leading-loose animate-text">
                  {steps[0].title}
                </div>
                <div className="text-center text-zinc-400 text-sm font-normal leading-tight animate-text">
                  {preventSingleWordWrap(steps[0].content)}
                </div>
              </div>
            </div>
            <div
              className={`w-[90%] h-auto rounded-[100%] border-b-8 border-x-8 border-b-sky-700 border-x-sky-800 flex justify-center animate-box`}
            >
              <div className="flex flex-col pb-12 w-[50%] xxl:pt-8">
                <div className="text-center text-white text-2xl leading-loose animate-text">
                  {steps[1].title}
                </div>
                <div className="text-center text-zinc-400 text-sm font-normal leading-tight animate-text">
                  {preventSingleWordWrap(steps[1].content)}
                </div>
              </div>
            </div>

            <div
              className={`w-[80%] h-auto rounded-[100%] border-b-8 border-x-8 border-b-sky-700 border-x-sky-800 flex justify-center animate-box`}
            >
              <div className="flex flex-col pb-6 xxl:pb-12 w-[50%] xxl:pt-8">
                <div className="text-center text-white text-2xl leading-loose animate-text">
                  {steps[2].title}
                </div>
                <div className="text-center text-zinc-400 text-sm font-normal leading-tight animate-text">
                  {preventSingleWordWrap(steps[2].content)}
                </div>
              </div>
            </div>
            <div
              className={`w-[70%] h-auto rounded-[100%] border-b-8 border-x-8 border-b-sky-700 border-x-sky-800 flex justify-center animate-box`}
            >
              <div className="flex flex-col pb-6 xxl:pb-12 w-[55%] xxl:pt-8 ">
                <div className="text-center text-white text-2xl leading-loose animate-text">
                  {steps[3].title}
                </div>
                <div className="text-center text-zinc-400 text-sm font-normal leading-tight animate-text">
                  {preventSingleWordWrap(steps[3].content)}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
