import React, { useEffect, useRef, useState } from "react";
import LeftQuarterCircle from "../../assets/Image/LeftQuarterCircle.png";
import Circle from "../../assets/Image/Circle.png";
import { CustomImage } from "../CustomImage/CustomImage";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import useWindowDimensions from "../../Hooks/ScreenDimensionsHook";
import useWindowHeight from "../../Hooks/ScreenHeight";

export const DualSolutions: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const width = useWindowDimensions();

  const isMobile = width < 1440;
  const height = useWindowHeight();
  const [isLandscape, setIsLandscape] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(width < 855 && height < 400);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width, height]);

  useGSAP(() => {
    gsap.fromTo(
      ".triangles",
      {
        rotate: -360,
        opacity: 0,
      },
      {
        rotate: 0,
        opacity: 1,
        duration: isMobile ? 3 : 1,
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 100%",
          end: "bottom 80%",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <div
      ref={sectionRef}
      className={`${
        isLandscape && "pb-20"
      } w-[100%] h-auto xxl:mt-64 xxl:pb-64 xxl:h-[0vh] px-4 md:p-20 flex md:flex-col flex-row items-center justify-center relative xxl:mb-[600px] xxs:my-[120px] `}
    >
      <div className="absolute flex md:flex-col gap-32 justify-between triangles xxs:pr-9 xxs:pb-11">
        {/* RETAIL PART */}
        <div className="flex flex-col ite-center relative x">
          <div className="flex justify-center items-center relative">
            <CustomImage
              className="w-[60%] md:w-[60%] xxl:w-[50%] h-auto md:rotate-90"
              src={LeftQuarterCircle}
              alt="RETAIL"
            />
            <div className="absolute text-center text-white">
              <div className="h-auto max-w-[275px] sm:max-w-[180px] flex flex-col justify-center items-start gap-4 pr-20 md:pr-0 md:pb-10">
                <h1 className="text-center text-white text-3xl xl:text-lg lg:text-base md:text-xs sm:text-[14px] font-medium">
                  RETAIL
                </h1>
                {!isMobile && (
                  <p className="text-start text-zinc-400 text-sm sm:text-xs md:text-xs leading-normal">
                    Custom retail trading solutions tailored to unique
                    requirements
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* INSTITUTIONAL PART */}
        <div className="flex flex-col items-center relative">
          <div className="flex justify-center items-center relative ">
            <CustomImage
              className="w-[60%] md:w-[60%] xxl:w-[50%]  rotate-180 md:rotate-[-90deg] h-auto"
              src={LeftQuarterCircle}
              alt="INSTITUTIONAL"
            />
            <div className="absolute text-center text-white">
              <div
                className={`h-auto max-w-[310px] sm:max-w-[180px] flex flex-col justify-center items-center gap-4 md:pl-0 md:pt-20 ${
                  isMobile && "md:pr-12 lg:pr-12 xs:pr-0"
                }`}
              >
                <h1
                  className={`text-center text-white text-3xl xl:text-lg lg:text-sm md:text-xs sm:text-xs font-medium pl-[5vh] 2xl:pl-[12vh] md:pl-0 lg:pl-5 xs:pl-0 ${
                    isLandscape && "ml-5"
                  }`}
                >
                  INSTITUTIONAL
                </h1>
                {!isMobile && (
                  <p className="text-end text-zinc-400 text-sm sm:text-xs md:text-xs pl-10 leading-normal">
                    Robust institutional infrastructure that operates in all
                    market conditions
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CENTRAL CIRCLE PART */}
      <div className={`flex flex-col items-center relative circle `}>
        <CustomImage
          className="w-[35%] md:w-[70%] xxl:w-[40%] xxs:w-[80vh] h-auto md:rotate-[90deg]"
          src={Circle}
          alt="Circle"
        />
        <div className="absolute top-[30%] w-full flex justify-center">
          <div
            className={`h-auto max-w-[360px] sm:max-w-[180px] flex flex-col justify-center items-center gap-4`}
          >
            <h1
              className={`text-center text-white text-5xl lg:text-xl md:text-xl font-medium xxs:mt-3`}
            >
              Dual
              <br /> Solutions
            </h1>
            {!isMobile && (
              <p className="text-center text-zinc-400 text-sm sm:text-xs md:text-xs leading-normal">
                QuantLabs bespoke solutions meet the
                <br /> needs of both institutions and retail
                <br /> business cases.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
