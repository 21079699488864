import { DualSolutions } from "../../components/DualSolutions/DualSolutions";
import { OurDevelopmentPipeline } from "../../components/OurDevelopmentPipeline/OurDevelopmentPipeline";
import { OurEdge } from "../../components/OurEdge/OurEdge";
import { WhoWeAre } from "../../components/WhoWeAre/WhoWeAre";
import { WhyUsSection } from "../../components/WhyUs/WhyUs";
import { features } from "../../Data/OurEdgeData";
import { CompaniesSlider } from "../../components/CompaniesSlider/CompaniesSlider";
import { HexagonGrid } from "../../components/Hexagon/HexagonGrid";
import { InformationSection } from "../../components/Information/InformationSection";
import { Services } from "../../components/Services/Services";
import { ClientsTestimonials } from "../../components/ClientsTestimonials/ClientsTestimonials";
import { ContactUsForm } from "../../components/ContactUs/ContactUsForm";
import { Section } from "../../types/Section/Section";

export const Main: React.FC = () => {
  return (
    <main className="bg-[#01060f] flex-grow overflow-hidden relative">
      <Section additionalClasses="pb-48 pt-10 sm:pb-32 px-28 sm:px-4 max-w-[1600px] mx-auto">
        <InformationSection />
      </Section>

      <Section
        id="about"
        additionalClasses="pl-28 sm:px-4 pb-48 sm:pb-32 max-w-[1600px] mx-auto"
      >
        <WhoWeAre />
      </Section>
      <Section additionalClasses="px-28 sm:px-4 pb-48 sm:pb-32 max-w-[1600px] mx-auto">
        <OurEdge features={features} />
      </Section>
      <Section additionalClasses="px-28 sm:px-4 pb-48 sm:pb-32 max-w-[1600px] mx-auto">
        <WhyUsSection />
      </Section>
      <Section additionalClasses="px-28 sm:px-4 pb-48 sm:pb-32 max-w-[1600px] mx-auto">
        <OurDevelopmentPipeline />
      </Section>
      <Section
        id="services"
        additionalClasses="h-screen px-28 sm:px-4 xxs1:pb-[120px] pb-64  md:pb-20 sm:pb-32 xs:pb-0 xs:max-h-[650px] max-w-[1600px] mx-auto"
      >
        <Services />
      </Section>
      <Section additionalClasses=" 3xl:h-screen sm:max-h-[60vh] sm:pb-12  ">
        <HexagonGrid />
      </Section>
      <Section additionalClasses="3xl:h-screen px-28 sm:px-4 p-48 sm:p-32 max-w-[1600px] mx-auto">
        <DualSolutions />
      </Section>
      <Section additionalClasses="pb-48 sm:pb-32 max-w-[1600px] mx-auto">
        <ClientsTestimonials />
      </Section>
      <Section additionalClasses="pb-12 sm:pb-32 xxs:pb-28 sm:pl-4 max-w-[1600px] mx-auto">
        <CompaniesSlider />
      </Section>
      <Section
        id="contact"
        additionalClasses="px-28 sm:px-4 pb-48 sm:pb-32 xxs:pb-20 max-w-[1600px] mx-auto"
      >
        <ContactUsForm />
      </Section>
    </main>
  );
};
