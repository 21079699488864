import React, { useRef, useEffect, useState } from "react";
import { ServiceDetails } from "./ServiceDetails";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import useWindowDimensions from "../../Hooks/ScreenDimensionsHook";
import useWindowHeight from "../../Hooks/ScreenHeight";

export const Services: React.FC = () => {
  const text =
    "QuantLabs provides end-to-end technology solutions. Whether you're looking to build a new product, optimize your existing systems, or improve your tech stack, we have the team and experience to help you succeed.";
  const sectionRef = useRef<HTMLDivElement>(null);
  const width = useWindowDimensions();
  const height = useWindowHeight();
  const [isLandscape, setIsLandscape] = useState<boolean>(false);
  console.log("====================================");
  console.log(height, "px", isLandscape);
  console.log("====================================");
  useEffect(() => {
    const handleResize = () => {
      // Перевірка на ландшафтний режим
      setIsLandscape(width < 855 && height < 400);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width, height]);

  const preventSingleWordWrap = (text: string) => {
    const words = text.split(" ");
    if (words.length > 2) {
      return (
        words.slice(0, -2).join(" ") + "\u00A0" + words.slice(-2).join("\u00A0")
      );
    }
    return text;
  };

  const isMobile = width < 1440;
  useGSAP(() => {
    if (sectionRef.current) {
      gsap.fromTo(
        ".service",
        {
          opacity: 0,
          x: -550,
        },
        {
          opacity: 1,
          x: 0,
          duration: 1,
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 90%",
            end: "bottom 100%",
            scrub: true,
          },
        }
      );
    }
    gsap.fromTo(
      ".service-details",
      {
        scale: 0,
        opacity: 0,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 80%",
          end: "bottom 80%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const circles = document.querySelectorAll(".service-details");

    circles.forEach((circle) => {
      circle.addEventListener("mouseenter", () => {
        gsap.to(circle, { scale: 1.1, ease: "power1.inOut", duration: 0.3 });
        gsap.to(
          Array.from(circles).filter((c) => c !== circle),
          { scale: 0.9, ease: "power1.inOut", duration: 0.3 }
        );
      });
      circle.addEventListener("mouseleave", () => {
        gsap.to(circle, { scale: 1, ease: "power1.inOut", duration: 0.3 });
        gsap.to(
          Array.from(circles).filter((c) => c !== circle),
          { scale: 1, ease: "power1.inOut", duration: 0.3 }
        );
      });
    });

    return () => {
      circles.forEach((circle) => {
        circle.removeEventListener("mouseenter", () => {
          gsap.to(circle, { scale: 1.1, ease: "power1.inOut", duration: 0.3 });
          gsap.to(
            Array.from(circles).filter((c) => c !== circle),
            { scale: 0.9, ease: "power1.inOut", duration: 0.3 }
          );
        });
        circle.removeEventListener("mouseleave", () => {
          gsap.to(circle, { scale: 1, ease: "power1.inOut", duration: 0.3 });
          gsap.to(
            Array.from(circles).filter((c) => c !== circle),
            { scale: 1, ease: "power1.inOut", duration: 0.3 }
          );
        });
      });
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className={`${isLandscape && "flex-col items-start"} 
    h-[80%] xxl:h-[60%]
      flex-row gap-5 md:flex-col justify-start items-center inline-flex md:justify-start`}
    >
      <div
        className={` relative h-auto flex-col justify-center items-center w-[35%] md:w-[100%] md:items-start ${
          isLandscape && "items-star justify-start t w-[100vh] left-[-200px]"
        } service`}
      >
        <div className="text-white text-5xl font-bold leading-10 pb-6 sm:pb-3 sm:text-3xl">
          Services
        </div>
        <div
          className={`text-zinc-400 text-xl font-normal 2xl:text-lg sm:text-sm ${
            isLandscape && "text-sm leading-[58px] min-w-[50vw]"
          } leading-[28px] `}
        >
          {preventSingleWordWrap(text)}
        </div>

        <div className="sm:hidden md:hidden w-96 h-96 absolute right-[280px] bottom-[280px] bg-gradient-to-br from-stroukBlue to-darkSky2 rounded-full blur-3xl" />
      </div>

      <div
        className={`relative xxs1:mb-[120px] ${
          isLandscape && "left-[-400px] top-[300px]"
        }`}
      >
        <div className="absolute left-[200px] bottom-[-120px] md:left-[-100px] xxs:left-[-130px] md:top-[40px]">
          <div
            className={`flex bottom-0 ${
              isLandscape && "w-[250px] h-[250px] mr-20"
            } w-[400px] h-[400px] md:w-[215px] md:h-[215px] xxs:w-[260px] xxs:h-[260px]  transform rounded-full bg-gradient-to-b from-transparent via-[rgba(2.90,131.12,222.33,0.14)] to-[rgba(10.35,85.27,138.56,0.80)] service-details `}
          >
            <ServiceDetails
              title="Technology Provider"
              description="From custom software development to off-the-shelf solutions, our team of skilled developers and engineers will leverage the latest technologies to build robust, scalable, and future-proof solutions that align with your business goals"
            />
          </div>
        </div>
        <div
          className={`absolute left-[370px] xxs:left-[-130px] xxs:top-[250px] md:left-[-20px] ${
            isLandscape && "left-[300px]"
          } ${!isMobile && ""} md:top-[200px]`}
        >
          <div
            className={`flex w-[400px] h-[400px] md:w-[215px] md:h-[215px] xxs:w-[260px] xxs:h-[260px] ${
              isMobile
                ? "transform rotate-105deg origin-top-right xxs:origin-top bg-gradient-to-t from-[rgba(10.35,85.27,138.56,0.80)] via-[rgba(2.90,131.12,222.33,0.14)] to-[rgba(10.35,85.27,138.56,0.80)] rounded-full"
                : "transform rotate-105deg origin-top-right bg-gradient-to-l from-transparent via-[rgba(2.90,131.12,222.33,0.14)] to-[rgba(10.35,85.27,138.56,0.80)] rounded-full"
            }  service-details ${
              isLandscape && "w-[250px] h-[250px] mt-[10px]"
            } `}
          >
            <ServiceDetails
              title="Consultancy"
              description="Our advisory services help projects improve their tech infrastructure, fueling their growth and ensuring businesses stay ahead and outperform competitors"
            />
          </div>
        </div>
        <div
          className={`absolute md:left-[-200px] xxs:left-[-130px] xxs:top-[450px] md:top-[200px]  ${
            isMobile ? "" : "2xl:left-[25px]"
          } left-[30px]`}
        >
          <div
            className={`flex w-[400px] h-[400px] md:w-[215px] md:h-[215px] xxs:w-[260px] xxs:h-[260px] ${
              isMobile
                ? "transform rotate-105deg origin-top-left  xxs:origin-top bg-gradient-to-t from-transparent via-[rgba(2.90,131.12,222.33,0.14)] to-[rgba(10.35,85.27,138.56,0.80)] rounded-full"
                : "transform rotate-105deg origin-top-left bg-gradient-to-r from-transparent via-[rgba(2.90,131.12,222.33,0.14)] to-[rgba(10.35,85.27,138.56,0.80)] rounded-full"
            } service-details ${
              isLandscape && "w-[250px] h-[250px] mt-[10px] ml-[0px]"
            } `}
          >
            <ServiceDetails
              title="Integration"
              description="Integrate your current infrastructure with our tech stack, enhancing its interoperability with various enterprise-grade technologies"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
